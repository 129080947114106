gsap.registerPlugin(CSSRulePlugin, Draggable, EasePack, PixiPlugin, EaselPlugin, ScrollToPlugin, CustomEase, ScrollTrigger, InertiaPlugin, );

'use strict';
var DURO = DURO || {};
var scrollInstance;
DURO.BUILD = (function() {

    var self;
    return {
        init: function() {
            // Find all controller in page
            // Define template or content
            console.log("INIT");
            self = this;
            if(isTouchScreendevice()) {
                $(".horizontal-scroll").addClass("is-draggable");
                $(".horizontal-scroll").attr("data-control", "SLIDER");
                $("body, html, .scroll-container").css({"overflow-y":"visible"});
                //$("html, body, .scroll-container").css({"overflow":"visible"});
                console.log("aqui")
            }
            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!DURO[controller]) {
                        return;
                    }

                    if (typeof DURO[controller] === 'function') {
                        var obj = new DURO[controller]();
                        obj.init(elem, data);
                    } else if (typeof DURO[controller] === 'object') {
                        DURO[controller].init(elem, data);
                    }
                }
            });

            // // Convert IMG to SVG source code inline
             this.svg('img.svg-convert');
            //
            // Load Lazy Images with animation
            this.lazyLoader();

            // INVIEW CONF
            inView.offset({
                top: 0,
                bottom: $(window).height()/7
            });
            inView(".animate-in h1, .animate-in h2, .animate-in h3, .animate-in h4, .animate-in h5, .animate-in h6, blockquote p, .animate-in p, .animate-in a.btn")
            .on('enter', el => {
                $(el).addClass("fade-in");
                $(el).removeClass("fade-out");
                $(el).addClass("in");
                $(el).removeClass("out");
            })
            .on('exit', el => {
                $(el).removeClass("fade-in");
                $(el).addClass("fade-out");
                $(el).removeClass("in");
                $(el).addClass("out");
            });
            inView("article, .line, .animate-in, blockquote, [data-animate-in-out]")
            .on('enter', el => {
                $(el).addClass("in");
                $(el).removeClass("out");
            })
            .on('exit', el => {
                $(el).removeClass("in");
                $(el).addClass("out");
            });

            inView(".b-lazy")
            .on('enter', el => {

                if($(el).hasClass("bg")) {
                    let bgImg = $(el).find("img").attr("src");
                    console.log("bgImg",bgImg)
                    $(el).css("backgroundImage", "url('"+bgImg+"')");
                    $(el).addClass("b-loaded");
                }
                $(el).addClass("b-loaded");
            }).on('exit', el => {
                $(el).removeClass("b-loaded");
            });

            // BG color switcher setup
            // var component = $('.component');
            // component.each(function(){
            //     let item = $(this);

            //     if(item.hasClass("dark-grey-color-bg")) {
            //         console.log(item);
            //         // TODO set colors vars in markup
            //         item.attr("data-textcolor", "#ffffff");
            //         item.attr("data-bgcolor", "#212020");
            //     } else {
            //         item.attr("data-textcolor", "#000000");
            //         item.attr("data-bgcolor", "#ffffff");
            //     }
            // });

            var component = $('.component');
            component.each(function(){
                let header = $(this).find("header");
                if(header.length) {
                    if(header.height() > $(this).height()) {
                        header.css({"position": "static", "transform": "none"});
                    }
                }
            })
            if(!isTouchScreendevice()) {
                const pageContainer = document.querySelector(".scroll-container");
                pageContainer.setAttribute("data-scroll-container", "");
                $(pageContainer).append('<div class="phantom-footer"></div>');

                scrollInstance = new LocomotiveScroll({
                    el: pageContainer,
                    smooth: true,
                    getDirection: true
                    // scrollFromAnywhere: true
                    // ,
                    // smartphone: {
                    //     smooth: true
                    // }
                });

                // scroll.on("scroll", );
                scrollInstance.on("scroll", function (t) {
                    document.documentElement.setAttribute("data-direction", t.direction);
                });
                scrollInstance.on('scroll', (obj) => {

                    ScrollTrigger.update();
                    DURO.NAV.render();
                    if($(".call-button").length) {
                        DURO.CALL_NAV.render();
                    }

                    // DELETE IN PRODUCTION
                    //DURO.LABELCOMPONENT.render();
                    // DELETE IN PRODUCTION
                    console.log(obj, obj.delta.y, obj.limit.y)
                    if(obj.delta) {
                        obj.delta = obj.delta;
                    } else {
                        obj.delta = obj.scroll;
                    }
                    if(obj.delta.y == obj.limit.y) {

                        $(".phantom-footer").css("position", "absolute");
                    }

                });

                ScrollTrigger.scrollerProxy(pageContainer, {
                    scrollTop(value) {
                    return arguments.length
                        ? scrollInstance.scrollTo(value, 0, 0)
                        : scrollInstance.scroll.instance.scroll.y;
                    },
                    getBoundingClientRect() {
                    return {
                        left: 0,
                        top: 0,
                        width: window.innerWidth,
                        height: window.innerHeight
                    };
                    },
                    pinType: pageContainer.style.transform ? "transform" : "fixed"
                });



                let horizontalSections = document.querySelectorAll(".horizontal-scroll:not(.is-draggable)");

                horizontalSections.forEach((horizontalSection) => {
                    let pinWrap = horizontalSection.querySelector(".pin-wrap");
                    let pinWrapWidth = pinWrap.offsetWidth;
                    let horizontalScrollLength = pinWrapWidth - window.innerWidth;
                    let progressEl = $(horizontalSection.querySelector(".progress-bar-container"));
                    gsap.to(pinWrap, {
                    scrollTrigger: {
                        scroller: "[data-scroll-container]",
                        scrub: true,
                        trigger: horizontalSection,
                        pin: true,
                        start: "top top",
                        end: () => `+=${pinWrapWidth}`,
                        invalidateOnRefresh: true,
                        onUpdate: function(e){
                            var progress = Math.ceil(e.progress * 100);
                            var total = $(e.pin).find("img").length;

                            gsap.to(progressEl.find(".progress-bar-current"), .3, {width: percentToPixel(progressEl.find(".progress-bar-current"), progress)})
                            // progressEl.find(".progress-bar-current").css("width", percentToPixel(progressEl.find(".progress-bar-current"), progress))
                            let currentY = (25 * (Math.floor(progress) * total) / 100)- 25;
                            if(currentY < 0 ) {
                                currentY = 0;
                            }
                            // console.log(total, progress, (progress * total) / 100, currentY)
                            gsap.to(progressEl.find(".progress-index li"), 1, {y: -currentY,});
                            // progressEl.find(".progress-index div").css("transform", "translateY("+(-currentY)+"px)");
                        }
                    },
                    x: -horizontalScrollLength,
                    ease: "none"
                    });
                });

                // ADD SCROLL TRIGGERS
                /* COLOR CHANGER */

                // const scrollColorElems = document.querySelectorAll("[data-menutheme]");
                // scrollColorElems.forEach((colorSection, i) => {
                //     const prevBg = i === 0 ? "" : scrollColorElems[i - 1].dataset.menutheme;
                //     // const prevText = i === 0 ? "" : scrollColorElems[i - 1].dataset.textcolor;

                //     ScrollTrigger.create({
                //     trigger: colorSection,
                //     scroller: "[data-scroll-container]",
                //     start: "top bottom",
                //     onEnter: function(){
                //         console.log(colorSection.dataset.menutheme);
                //        $(".main-nav").addClass(colorSection.dataset.menutheme)
                //     },
                //     onLeave: function(){
                //         console.log(prevBg);
                //         $(".main-nav").removeClass("light")

                //     }
                //     });
                // });

                ScrollTrigger.addEventListener("refresh", () => scrollInstance.update());
                ScrollTrigger.refresh();

            }
            // CONFIG anchors labels
            $("*[data-anchor-labels]").each(function(){
                $(this).find("a").each(function(){
                    let label = $(this).text();
                    $(this).attr("data-label", label);
                    $(this).addClass("d-inline-block");
                })
            })

            // set menu when dark mode is on
            if($("body").hasClass("dark-grey-color-bg") && !$(".main-nav").hasClass("yellow")) {
                $(".main-nav").addClass("yellow");
            }
            if(!isTouchScreendevice()) {
                window.addEventListener("resize", this.resizeHandler);
            }
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: 0,
                success: function(element) {

                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 10);
                }
            });

            return bLazy;
        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        resetScroll: function(){
            console.log("resetScroll");
            $(".phantom-footer").css("position", "static");
            scrollInstance.destroy();

            scrollInstance.start();
            // DURO.BUILD.scrollInstance.scrollInstance.destroy();
            // scroll.start();
            DURO.CALL_NAV.render();
        },
        resizeHandler: function(e){
            console.log("resizeHandler", scrollInstance);
            scrollInstance.destroy();
            scrollInstance.init();
        }
    };
})();
function hexToRgbA(hex, alpha = 1){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
    }
    // throw new Error('Bad Hex');
}


DURO.HOME = (function() {
    var self;
    return {
        init: function(elem) {


            if($(elem).data("theme")) {
                
                let theme = $(elem).data("theme");
                
                let style = ("<style>"+
                "h1,"+
                ".use-cases-list li a,"+
                ".progress-bar-container,"+
                ".horizontal-scroll .progress-bar-container.yellow"+
                ".progress-bar-container.yellow .progress-bar-total, .progress-bar-container.yellow .progress-index *,"+
                ".crossselling-light a span,"+
                ".list .list-container ul li:hover a,"+
                ".numeration h2, "+
                ".career-contact .career-card p a,"+
                "button, a.btn,"+
                "button:hover, a.btn:hover,"+
                ".dark-grey-color-bg .use-cases-list a,"+
                ".keywordset .list-container > ul:not(.use-cases-list) li > a.active,"+
                ".prev-next-navigation a.arrow,"+
                ".component li::marker,"+
                ".component a[data-label]"+
                " {"+
                "    color: "+theme+" !important"+
                " }"+
                "h4:after,"+
                ".use-cases-list li:after,"+
                ".use-cases-list li:before,"+
                ".circle-cursor,"+
                ".follow,"+
                "h3:after,"+
                "h1:after,"+
                ".line:after,"+
                ".call-button,"+
                ".progress-bar-container .progress-bar span,"+
                ".progress-bar-container .progress-bar:after,"+
                ".progress-bar-container.yellow .progress-bar span,"+
                ".progress-bar-container.yellow .progress-bar .progress-bar-current, "+
                ".progress-bar-container.yellow .progress-bar:after, "+
                ".proximity span,"+
                ".text-teaser-5 article:after,"+
                ".quote blockquote:after,"+
                ".prev-next-navigation a.arrow:after"+
                "{"+
                "    background-color: "+theme+" !important;"+
                "}"+
                "body a[data-label]:after {"+
                "    text-decoration-color: "+theme+" !important;"+
                "}"+
                "button, a.btn {"+
                "    border-color: "+theme+" !important;"+
                "}"+
                "button:hover, a.btn:hover {"+
                "    opacity: .5 !important;"+
                "}"+
                ".list.bg"+
                " {"+
                "    background-color: "+hexToRgbA(theme, .1)+" !important"+
                " }"+
                "</style>");
                $("body").append(style);
            }

        }
    }
})

DURO.KEYWORD_HEADER = (function() {
    var self;
    return {
        init: function(elem) {
            console.log("KEYWORD_HEADER");

            // gsap.to(".keyword-header header", {
            //     yPercent: 10,
            //     ease: "none",
            //     scrollTrigger: {
            //         trigger: ".keyword-header header",
            //         scrub: 1
            //     }
            // });

            // gsap.to(".smartphone", {
            //     yPercent: -15,

            //     scrollTrigger: {
            //         start: "top 10%",
            //         end: "bottom 50%",
            //         trigger: ".smartphone",
            //         scrub: 1
            //     }
            // });


        }
    }
})();

DURO.CAROUSEL_SLIDER = (function() {
    var self;
    var el;
    var total;
    var progressEl;
    return {
        init: function(elem) {

            console.log("CAROUSEL_SLIDER");
            self = this;
            self.el = elem;
            total = $(elem).find("figure").length;
            self.progressEl = $(elem).parent().find(".progress-bar-container");
            if(total == 1) {
                self.progressEl.hide();
            }
            
            $(elem).on('afterChange', this.updateProgress);
            $(elem).slick({
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true
            });
            $(elem).on("click", function(){
                $(elem).slick('slickNext');
            })

        },
        updateProgress: function(event, slick, currentSlide){
            currentSlide = currentSlide + 1;
            var progress = Math.ceil(currentSlide * 100 / total);

            console.log(currentSlide, total,  progress);
            gsap.to(self.progressEl.find(".progress-bar-current"), .3, {width: percentToPixel(self.progressEl.find(".progress-bar-current"), progress)})
            let currentY = (26 * (progress * total) / 100)- 26;
            if(currentY < 0 ) {
                currentY = 0;
            }
            // console.log(total, progress, (progress * total) / 100, currentY)

            gsap.to(self.progressEl.find(".progress-index li"), .5, {y: -currentY});
        }
    }
})

DURO.GIF_TEXT_TEASER = (function() {
    var self;
    return {
        init: function(elem) {
            console.log("GIF_TEXT_TEASER");
        }
    }
})
let mousepos = {x: 0, y: 0};
// TODO
// define just one listener for mouse move
function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
};


window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));
document.addEventListener('touchmove', function(e) {
    e.preventDefault();
    var touch = e.targetTouches[0];
    if (touch) {
        getMousePos(touch);
    }
}, false);

DURO.PROXIMITY = (function() {
    var self;
    var el;
    var classEl;
    var requestAnimationFrameID;
    var distanceToTrigger;
    var rect;
    var firstPlan;
    var secondPlan;
    var id;
    return {
        init: function(elem) {
            // console.log("PROXIMITY");

            self = this;

            self.el = elem;
            $(self.el).append("<span></span>");
            self.firstPlan = elem.getElementsByTagName("img")[0];
            if(self.firstPlan === undefined) {
                self.firstPlan = elem;
            }
            self.secondPlan = elem.getElementsByTagName("span")[0];

            this.classEl = "."+$(this.el).attr('class');
            this.id = "#"+$(this.el).attr('id');
            this.renderedStyles = {
                tx: {previous: 0, current: 0, amt: 0.1},
                ty: {previous: 0, current: 0, amt: 0.1},
                tx2: {previous: 0, current: 0, amt: 0.05},
                ty2: {previous: 0, current: 0, amt: 0.05}
            };
            // button state (hover)
            this.state = {
                hover: false
            };
            // calculate size/position
            this.calculateSizePosition();
            // init events
            this.initEvents();
            // loop fn
            // requestAnimationFrame(() => this.render());
            window.addEventListener('resize', this.calculateSizePosition);
        },
        initEvents: function(){
            // console.log("initEvents");
            // self.requestAnimationFrameID = requestAnimationFrame(() => self.render());
            // console.log(this.id);
            inView(this.id)
            .on('enter', function(){
                // console.log("in-view")
                self.requestAnimationFrameID = requestAnimationFrame(() => self.render());
            })
            .on('exit', function(){
                // console.log("off-view")
                cancelAnimationFrame(self.requestAnimationFrameID);
            });
        },
        calculateSizePosition: function(){
            // console.log("calculateSizePosition");
            // size/position
            // self.rect = self.el.getBoundingClientRect();
            self.rect = getAbsoluteBoundingRect(self.el);
            // draw(self.rect);
            // the movement will take place when the distance from the mouse to the center of the button is lower than this value
            self.distanceToTrigger = self.rect.width*1.1;

            // console.log("distanceToTrigger", self.distanceToTrigger, self.rect);
        },
        render: function(){
            self.rect = getAbsoluteBoundingRect(self.el);
            //draw(self.rect);
            // calculate the distance from the mouse to the center of the button
            var distanceMouseButton = distance(mousepos.x, mousepos.y, self.rect.left + self.rect.width/2, self.rect.top + self.rect.height/2);

            // new values for the translations and scale
            let x = 0;
            let y = 0;
            // console.log(distanceMouseButton < self.distanceToTrigger, mousepos, distanceMouseButton);

            if ( distanceMouseButton < self.distanceToTrigger ) {
                if ( !self.state.hover ) {
                    self.enter();
                }
                x = (mousepos.x - (self.rect.left + self.rect.width/2))*.3;
                y = (mousepos.y - (self.rect.top + self.rect.height/2))*.3;
            }
            else if ( self.state.hover ) {
                self.leave();
            }
            self.renderedStyles['tx'].current = self.renderedStyles['tx2'].current = x;
            self.renderedStyles['ty'].current = self.renderedStyles['ty2'].current = y;

            for (const key in self.renderedStyles ) {
                self.renderedStyles[key].previous = lerp(self.renderedStyles[key].previous, self.renderedStyles[key].current, self.renderedStyles[key].amt);
            }

            self.firstPlan.style.transform = `translate3d(${self.renderedStyles['tx'].previous}px, ${self.renderedStyles['ty'].previous}px, 0)`;

            self.secondPlan.style.transform = `translate3d(${self.renderedStyles['tx2'].previous}px, ${self.renderedStyles['ty2'].previous}px, 0)`;
            self.requestAnimationFrameID = requestAnimationFrame(() => self.render());
        },
        enter: function() {
            console.log("enter");
            self.state.hover = true;
        },
        leave: function() {
            self.state.hover = false;
            console.log("leave");
       }
    }
})
function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}
DURO.KEYWORDSET = (function() {
    var self;
    var el;
    var data;
    var current = 0;
    var subCategoryState = false;
    var subCategoryDataPanel;
    var template;
    var structure;
    var maxPerPage = 6;
    var currentPage = 1;
    var $buttonLoadMore;
    var filteredData;
    var filtersOn = [];
    return {
        init: function(elem) {
            console.log("KEYWORDSET");
            self = this;
            self.el = elem;
            self.subCategoryDataPanel = $(elem).find(".case-container");
            self.subCategoryDataPanel.on("click",".close-button", function(e){
                e.preventDefault();
                self.casesOut();
            });
            console.log("current", $(elem).data("current"));
            if($(elem).data("current")) {
                current = $(elem).data("current");
            }
            $buttonLoadMore = $(elem).find(".button-more-container");
            template = $(elem).find('.list-container script[type="text/template"]')[0];
            structure = template.cloneNode(true).innerHTML;
            // TO DO reaplce by ajax call to ID
            var dataSrc = $(elem).data("src-json");
            // console.log(dataSrc);
            $.getJSON( dataSrc, function( data ) {
                self.data = data;
                filteredData = self.data;
                self.toggleLoadMoreButton(data);
                self.categoryIn(current);
                $(elem).find(".button-more-container").on("click", function(e){
                    self.loadSubCategoriesNextPage();
                });
                if(!$(self.el).hasClass("referenzen")){
                    $(elem).find(".sub-categories-list").on("click","li a", function(e){
                        console.log("sub")
                        e.preventDefault();
                        $(elem).find(".sub-categories-list li a").removeClass("active")
                        self.selectCases(this);
                        // $(this).parent().addClass("opened");
                    });
                } else {
                    self.initReferenzenEvents();
                }
            });

            $("[data-keywordset-nav] a").on("click", function(e){
                var id = $(this).data("id");
                if($(self.el).length && (id != undefined) && !$(self.el).hasClass("referenzen")) {
                    e.preventDefault();
                    self.selectCategory(id);
                }
            });
            if(self.subCategoryDataPanel.length) {
                gsap.to(self.subCategoryDataPanel, 0, {x: ($(document).width() - self.subCategoryDataPanel.parent().offset().left) + 20});
            }
            
        },
        initReferenzenEvents: function() {

            $(self.el).find(".filters").on("click touchstart", "li a", function(e){
                filtersOn = [];
                e.preventDefault();
                if($(this).data("filter") != "") {
                    
                    $(self.el).find(".filters li a").removeClass("active");
                    $(this).addClass("active");
                    $(self.el).find(".filters li a").each(function(){
                        let filter  = $(this).data("filter");
                        if($(this).hasClass("active") && filter != "") {
                            if(filter != "") {
                                filtersOn.push(filter);
                            }
                        }
                    })
                } else {
                    self.removeFilters();
                }

                console.log(filtersOn);
                if(!filtersOn.length) {
                    self.removeFilters();
                } else {
                    self.filterData(filtersOn);
                }

            });
        },
        removeFilters: function(){
            $(self.el).find(".filters li a").removeClass("active");
            filteredData = self.data;
            console.log("all", filteredData);
            self.filterData(filtersOn);
            $(self.el).find(".filters li a:eq(0)").addClass("active");
        },
        loadSubCategoriesNextPage: function(callback){
            currentPage++;
            // console.log(currentPage);
            // console.log(filteredData);
            let totalPages = Math.ceil(filteredData["categories"][current]["subcategories"].length / maxPerPage);
            if(currentPage > totalPages) {
                currentPage = 1;
            }
            // console.log("load next page", currentPage,filteredData["categories"][current]["subcategories"].length);
            this.subCategoriesOut();
            this.subCategoriesIn(current);
        },
        toggleLoadMoreButton: function(_data) {
            // console.log(_data.length, maxPerPage);
            if(_data.length > maxPerPage) {
                $buttonLoadMore.css({"display": "block", "opacity": 0});
                gsap.to($buttonLoadMore, .2, {opacity: 1, overwrite: true});
            } else {
                gsap.to($buttonLoadMore, .2, {opacity: 0, onComplete: function(){
                    $buttonLoadMore.hide();
                }});
            }
        },
        selectCategory: function(id){
            self.categoryOut(id);
            this.subCategoriesOut();
            current = id;
        },
        categoryOut: function(id) {
            let h1 = $(self.el).find(".category-container header h1");
            let h5 = $(self.el).find(".category-container header h5");

            h1.addClass("remove-fade-right");
            h5.addClass("remove-fade-right");

            let el = $("[data-keywordset-nav] li a[data-id="+(id)+"]");
            $("[data-keywordset-nav] li a").removeClass("active");
            el.addClass("active");

            self.current= id;

            gsap.to(self, .6, {onComplete: function(){
                self.categoryIn(id);
            }})
            self.casesOut();

        },
        categoryIn: function(id) {
            let h1 = $(self.el).find(".category-container header h1");
            let h5 = $(self.el).find(".category-container header h5");

            let el = $("[data-keywordset-nav] li a[data-id="+(self.current)+"]");
            let title = el.data("title");
            let description = el.data("description");

            h1.html(title);
            h5.html(description);

            h1.removeClass("remove-fade-right").addClass("fade-in");
            h5.removeClass("remove-fade-right").addClass("fade-in");

            currentPage = 1;
            this.subCategoriesIn(id);

        },
        subCategoriesIn: function(id){
            $(self.el).find('.sub-categories-list').html("");

            let _data = filteredData["categories"][id]["subcategories"];
            self.toggleLoadMoreButton(_data);
            _data = paginate(_data, maxPerPage, currentPage);
            let _cases_links_array = "";
            let _cases_labels_array = "";
            let _cases_links = "";
            var templateBuild;
            for (var i = 0; i < _data.length; i++) {
                if($(self.el).hasClass("referenzen")){
                    let _cases = _data[i]["cases"];
                    _cases_links = "";
                    _cases_labels_array = "";
                    _cases_links_array = "";
                    for(let c = 0; c < _cases.length; c++) {
                        _cases_labels_array += _cases[c].case;
                        if(c < _cases.length-1) {
                            _cases_labels_array += ' | ';
                        }
                    }
                } else {
                    let _cases = _data[i]["cases"];
                    _cases_links = "";
                    _cases_labels_array = "";
                    _cases_links_array = "";
                    for(let c = 0; c < _cases.length; c++) {
                        _cases_links_array += '"'+_cases[c].link+'"';
                        _cases_labels_array += '"'+_cases[c].case+'"';
                        if(c < _cases.length-1) {
                            _cases_links_array += ',';
                            _cases_labels_array += ',';
                        }
                    }
                }
                // console.log(_cases_links_array);
                templateBuild = DURO.BUILD.templateEngine(structure, {
                  title: _data[i].category,
                  description: _data[i].description,
                  subcategory_link: _data[i].link,
                  cases_links_array: _cases_links_array,
                  cases_labels_array: _cases_labels_array,
                  cases_links: _cases_links
                });

                $(templateBuild).appendTo($(self.el).find(".sub-categories-list"));
            }
            if($('.sub-categories-list li').data("control")){
                $('.sub-categories-list li').each(function(index, elem) {
                    var data = $(elem).data(),
                        control = data.control.split(',');

                    for (var i = 0; i < control.length; i++) {
                        var controller = control[i];
                        if (!DURO[controller]) {
                            return;
                        }

                        if (typeof DURO[controller] === 'function') {
                            var obj = new DURO[controller]();
                            obj.init(elem, data);
                        } else if (typeof DURO[controller] === 'object') {
                            DURO[controller].init(elem, data);
                        }
                    }
                });
            }
            // SHOW AGAIN
            TweenMax.staggerFromTo($(self.el).find(".list-container .sub-categories-list li"), 0.1, {opacity:0, y: -20}, {opacity:1, y: 0, ease: Quad.easeOut, delay: .1}, 0.01);
            $(self.el).find(".sub-categories-list li a").removeClass("active");
        },
        subCategoriesOut: function(callbak){
            TweenMax.staggerTo(selectReversed(".keywordset .list-container .sub-categories-list li"), 0, {opacity:0, y: 20, onComplete: callbak}, 0.01);
        },
        selectCases: function(subcategory) {
            subcategory = $(subcategory);
            self.casesOut(self.casesIn, subcategory);
        },
        casesOut: function(outCallback, params){
            $(self.el).find(".sub-categories-list li a").removeClass("active");

            if(!outCallback) {
                function callback() {
                    self.subCategoryDataPanel.removeClass("show");
                }
                outCallback = callback;
            }
            let heading = self.subCategoryDataPanel.find("h5")
            let list = self.subCategoryDataPanel.find(".use-cases-list");
            gsap.to(self.subCategoryDataPanel, .3, {x: ($(document).width() - self.subCategoryDataPanel.parent().offset().left) + 20, onComplete: outCallback, onCompleteParams: params});
        },
        casesIn: function(params){
            let subcategory = $(params);
            subcategory.addClass("active");
            let delay = 0;
            if(params) {
                delay = 1;
                self.setSubCategoryData(subcategory.data("label"), subcategory.data("cases-links"), subcategory.data("cases-labels"), subcategory.data("link"));
            }
            let heading = self.subCategoryDataPanel.find("h5")
            let list = self.subCategoryDataPanel.find(".use-cases-list");
            TweenMax.staggerFromTo($(self.el).find(".case-container .use-cases-list li"), 0.2, {y: -20}, {opacity:1, y: 0, ease: Quad.easeOut, delay: .3}, 0.07)
            // let _panel_x = percentToPixel(self.subCategoryDataPanel, 96) + "px";
            // if($(window).width() <= 1850) {
            let _panel_x = "0";
            // }
            gsap.to(self.subCategoryDataPanel, .6, {x: _panel_x, ease: Cubic.easeOut, overwrite: true});
            // self.subCategoryDataPanel.addClass("show");
        },
        setSubCategoryData: function(label, casesLinks, casesLabels, link){
            let heading = self.subCategoryDataPanel.find("h5")
            heading.html(label);

            let list = self.subCategoryDataPanel.find(".use-cases-list");
            list.html("");

            let anchor = self.subCategoryDataPanel.find("a");
            anchor.attr("href", link);


            // list.addClass("in");
            for(let i = 0; i < casesLinks.length; i++) {
                var li = '<li><a href="'+casesLinks[i]+'">'+casesLabels[i]+'</a></li>';
                list.append(li);
            }
        },
        filterData: function(filtersIn) {
            let result = {categories: [{subcategories:[] }]};
            let _data  = self.data;
            let _foundIndexs = [];

            for(let i = 0; i < _data.categories[0].subcategories.length; i ++) {
                if(filtersIn.length > 0) {
                    for(let f = 0; f < filtersIn.length; f++) {
                        let found = $.grep( _data.categories[0].subcategories[i].cases, function( n, i ) { return n.case==filtersIn[f]; });
                        if(found.length) {

                            if(!_foundIndexs.includes(i)) {
                                _foundIndexs.push(i);
                                result.categories[0].subcategories.push(_data.categories[0].subcategories[i]);
                            }
                        }
                    }
                }
            }
            if(filtersIn.length > 0) {
                filteredData = result;
            }
            currentPage = 0;
            self.loadSubCategoriesNextPage();
        }
    }
})
function percentToPixel(_elem, _perc){
    return (_elem.parent().outerWidth()/100)* parseFloat(_perc);
}
DURO.SOCIAL_MEDIA_WALL = function () {
    var self;
    return {
        init: function init(elem) {
            self = this;
            let instagramApiKey = $(elem).data('instagram-api');
            let instagramApiUrl = $(elem).data('instagram-url');
            let itemsPerAction = $(elem).find('.insta-feed').data('items-count');
            let currentState = 0;
            let all = 0;
            $.ajax({
                url: instagramApiUrl+instagramApiKey,
                jsonp: "callback",
                dataType: "jsonp",
                success: function( response ) {
                    console.log( response );
                    all = response.data.length;
                    $(elem).find(".load-more-arrows").on("click", function () {
                        //self.animOut(self.animIn);
                        if ((currentState*itemsPerAction) >= all) {
                          currentState = 0;
                        }
                        currentState++;
                        self.mediaPull(currentState, itemsPerAction, response.data);
                        self.animIn();
                        $(this).addClass("loaded");
                    }).on("mouseout", function () {
                        $(this).removeClass("loaded");
                    });

                    $(elem).find(".load-more-arrows").click();
                }
            });
        },
        mediaPull: function mediaPull(state, per, items) {
            var to = (per*state);
            var from = (to-per);
            var i = 0;
            $('.insta-feed').html('');
            for (i = from; i < to; i++) {
              $('.insta-feed').append('<div class="col-lg-3 col-md-6 col-xs-1"><div class="box" id="box_'+i+'" data-animate-in-out></div></div>');
              console.log(items[i]);
              if (items[i].media_type == 'IMAGE') {
                $('#box_'+i).css({"background-image":"url("+items[i].media_url+")"});
                $('#box_'+i).css({"background-size":"100%"});
              }
              if (items[i].media_type == 'CAROUSEL_ALBUM') {
                $('#box_'+i).css({"background-image":"url("+items[i].media_url+")"});
                $('#box_'+i).css({"background-size":"100%"});
              }
              if (items[i].media_type == 'VIDEO') {
                $('#box_'+i).html('<video autoplay autobuffer muted playsinline loop><source src="'+items[i].media_url+'" type="video/mp4"></video>');
              }
              $('#box_'+i).append('<a data-cursor-over-bg="#FFFF00" target="_blank" href="'+items[i].permalink+'" class="link-to-post"></a>');

            }

        },
        animIn: function animIn() {
            TweenMax.staggerTo(".box", 0, { opacity: 1, y: 20 }, 0.1, self.animInEndHandler);
        },
        animInEndHandler: function animInEndHandler() {
            console.log("animInEndHandler");
        },
        animOut: function animOut(endHandler) {
            console.log(endHandler);
            if (!endHandler) {
                endHandler = self.animOutEndHandler;
            }
            TweenMax.staggerTo(selectReversed(".box"), 0, { opacity: 0, y: 0 }, 0.2, endHandler);
        },
        animOutEndHandler: function animOutEndHandler() {
            console.log("animOutEndHandler");
        }
    };
}();

DURO.VIDEO = (function() {
    var vid;
    var self;
    var _muted = true;
    var id;
    var requestAnimationFrameID;
    var el;
    return {
        init: function(elem) {
            self = this;
            self.el = elem;
            id = $(elem).attr("id");

            vid = document.getElementById(id);

            if($(elem).parent().find(".progress").length) {
                vid.addEventListener('play', (event) => {
                    console.log(event);
                    self.requestAnimationFrameID = setInterval(self.render, 100);
                });
                vid.addEventListener('pause', (event) => {
                    console.log(event);
                    clearInterval(self.requestAnimationFrameID);
                });
            }

            
            $(elem).on("click", function(){

                if (vid.muted === true) {
                    // console.log("vid.muted", vid.muted, vid.volume);
                    _muted = false;
                    self.soundTo(1);
                    vid.muted = false;
                    $(elem).data("cursor-over-label", "sound off");
                    DURO.CURSOR.updateLabel("sound off");

                }
                else if (vid.muted === false) {

                    // vid.volume = 0;
                    self.soundTo(0);
                    $(elem).data("cursor-over-label", "sound on");
                    DURO.CURSOR.updateLabel("sound on");
                    _muted = true;
                }
            })
            inView("video")
            .on('enter', el => {

                if(!_muted && !vid.muted) {
                    // self.play();
                    self.soundTo(1, self.play);
                } else {
                    self.play();
                    vid.volume = 0;
                    self.soundTo(1);
                }
            })
            .on('exit', el => {
               if(_muted) {
                    self.stop();
                } else {
                    self.soundTo(0, self.stop);
                }
            });
        },
        soundTo: function(volumeTo, callback){
            // console.log("soundTo",_muted, volumeTo, vid.volume);
            if(!_muted) {
                if(volumeTo > 0) {
                    eval(callback);
                }
                var volume = {value:vid.volume}
                // console.log("soundTo", vid.volume);
                TweenMax.to(volume, 1, {
                value:volumeTo,
                ease:Quad.easeOut,
                    onUpdate:function() {
                        console.log(volume.value);
                        vid.volume = volume.value;
                        console.log("soundTo", vid.volume);
                    }, onComplete: function() {
                        if(volumeTo == 0) {
                            vid.muted = true;
                        }
                    }
                });
            } else {
                eval(callback);
            }
        },
        play: function() {
            // vid.muted = false;
            vid.play();
        },
        stop: function() {
            vid.pause();
        },
        forcePlay: function(volumeTo){
            this.play();
            vid.muted = false;
            vid.volume = 0;
            var volume = {value:vid.volume}
            // console.log("soundTo", vid.volume);
            TweenMax.to(volume, 1, {
            value:volumeTo,
            ease:Quad.easeOut,
                onUpdate:function() {
                    // console.log(volume.value);
                    vid.volume = volume.value;
                    // console.log("soundTo", vid.volume);
                }, onComplete: function() {
                    if(volumeTo == 0) {
                        vid.muted = true;
                    }
                }
            });

        },
        render: function() {
            let totalWidth = $(self.el).parent().find(".progress").width();
            let vidPercentage = vid.currentTime * 100 / vid.duration ;

            let _width = vidPercentage *  totalWidth / 100
            // console.log("vidPercentage", vid.currentTime,vid.duration, vidPercentage);

            gsap.to($(self.el).parent().find(".progress .bar"), .4, {width: _width, ease: Linear.none});
        }
    }
})();

DURO.NAV = (function() {
    var self;
    var el;
    return {
        init: function(elem) {
            this.el = elem;

            self = this;
            self.el = elem;
            this.burguer = $(elem).find(".burguer-container, .close-button");

            this.initEvents();

        },
        initEvents() {
            this.burguer.on("click", function(){
                $(self.el).toggleClass("active");
                // $(".component").toggleClass("menu-open-hide");
                if(!$(self.el).hasClass("active")) {
                    DURO.SEARCH.closeSearch();
                }
            });
             //this.requestAnimationFrameID = requestAnimationFrame(() => this.render(this.el));
        },render: function(elem){
            var burguer = $('.burguer-container');
            var component = $('.component');

            component.each(function(){
                let item = $(this);

                if((collision(item, burguer) && inView.is(this))) {
                    if(item.data("menutheme")) {
                        let theme = item.data("menutheme");

                        if(!theme) {
                            theme = "yellow";
                        }
                        $(self.el).addClass(theme);
                        $(self.el).find(".logo").addClass(theme);
                    }
                    else {
                        $(self.el).removeClass("light");
                        $(self.el).removeClass("yellow");

                        $(self.el).find(".logo").removeClass("light");
                        $(self.el).find(".logo").removeClass("yellow");
                    }
                }
            });

            //self.requestAnimationFrameID = requestAnimationFrame(() => self.render(elem));
        }
    }
})();

DURO.LIST_LARGE = (function() {
    var self;

    return {
        init: function(elem) {

            self = this;
            
        }
    }
})

let mousePosCache = mousepos;
let direction = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};
const images = [];
DURO.LISTBG_ITEM = (function() {
    var self;

    return {
        init: function(elem) {
            // console.log("LISTBG_ITEM");
            self = this;
            this.DOM = {el: elem};

            this.inMenuPosition = $(elem).index();

            this.animatableProperties = {

                tx: {previous: 0, current: 0, amt: 0.08},

                ty: {previous: 0, current: 0, amt: 0.08},

                rotation: {previous: 0, current: 0, amt: 0.02},

                brightness: {previous: 1, current: 1, amt: 0.02}
            };

            this.img = $(elem).data("img");

            this.DOM.textInner = this.DOM.el.querySelector('.menu__item-textinner');
            this.layout();
            this.initEvents();
        },
        layout: function(){
            this.DOM.reveal = document.createElement('div');
            this.DOM.reveal.className = 'hover-reveal';

            this.DOM.revealInner = document.createElement('div');
            this.DOM.revealInner.className = 'hover-reveal__inner';
            this.DOM.revealImage = document.createElement('div');
            this.DOM.revealImage.className = 'hover-reveal__img';

            images.push(this.img);

            this.DOM.revealImage.style.backgroundImage = 'url('+this.img+')';

            this.DOM.revealInner.appendChild(this.DOM.revealImage);
            this.DOM.reveal.appendChild(this.DOM.revealInner);
            this.DOM.el.appendChild(this.DOM.reveal);
        },
        calcBounds() {
            this.bounds = {
                el: this.DOM.el.getBoundingClientRect(),
                reveal: this.DOM.reveal.getBoundingClientRect()
            };
        },
        initEvents() {
            this.mouseenterFn = (ev) => {

                this.showImage();
                this.firstRAFCycle = true;

                this.loopRender();
            };
            this.mouseleaveFn = () => {

                this.stopRendering();

                this.hideImage();
            };

            this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
            this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
            console.log($(this.DOM.el).find("a span"));
            if(!$(this.DOM.el).find("a span").length) {
                $(this.DOM.el).find("a").css("width", "100%");
            }
        },
        showImage() {
            gsap.killTweensOf(this.DOM.revealInner);
            gsap.killTweensOf(this.DOM.revealImage);

            this.tl = gsap.timeline({
                onStart: () => {

                    this.DOM.reveal.style.opacity = 1;

                    gsap.set(this.DOM.el, {zIndex: images.length});
                }
            })
            .to(this.DOM.revealInner, 0.2, {
                ease: 'Sine.easeOut',
                startAt: {x: direction.x < 0 ? '-100%' : '100%'},
                x: '0%'
            })
            .to(this.DOM.revealImage, 0.2, {
                ease: 'Sine.easeOut',
                startAt: {x: direction.x < 0 ? '100%': '-100%'},
                x: '0%'
            }, 0);
        },
        hideImage() {

            gsap.killTweensOf(this.DOM.revealInner);
            gsap.killTweensOf(this.DOM.revealImage);

            this.tl = gsap.timeline({
                onStart: () => {
                    gsap.set(this.DOM.el, {zIndex: 1});
                },
                onComplete: () => {
                    gsap.set(this.DOM.reveal, {opacity: 0});
                }
            })
            .to(this.DOM.revealInner, 0.2, {
                ease: 'Sine.easeOut',
                x: direction.x < 0 ? '100%' : '-100%'
            })
            .to(this.DOM.revealImage, 0.2, {
                ease: 'Sine.easeOut',
                x: direction.x < 0 ? '-100%' : '100%'
            }, 0);
        },
        loopRender() {
            if ( !this.requestId ) {
                this.requestId = requestAnimationFrame(() => this.render());
            }
        },
        stopRendering() {
            if ( this.requestId ) {
                window.cancelAnimationFrame(this.requestId);
                this.requestId = undefined;
            }
        },

        render() {
            this.requestId = undefined;

            if ( this.firstRAFCycle ) {
                this.calcBounds();
            }

            const mouseDistanceX = clamp(Math.abs(mousePosCache.x - mousepos.x), 0, 100);

            direction = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};

            mousePosCache = {x: mousepos.x, y: mousepos.y};

            this.animatableProperties.tx.current = Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.reveal.width/2;
            this.animatableProperties.ty.current = Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height/2;
            this.animatableProperties.rotation.current = this.firstRAFCycle ? 0 : map(mouseDistanceX,0,100,0,direction.x < 0 ? 60 : -60);
            this.animatableProperties.brightness.current = this.firstRAFCycle ? 1 : map(mouseDistanceX,0,100,1,4);
            this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerp(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
            this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerp(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
            this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerp(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);
            this.animatableProperties.brightness.previous = this.firstRAFCycle ? this.animatableProperties.brightness.current : lerp(this.animatableProperties.brightness.previous, this.animatableProperties.brightness.current, this.animatableProperties.brightness.amt);

            gsap.set(this.DOM.reveal, {
                x: this.animatableProperties.tx.previous,
                y: this.animatableProperties.ty.previous,
                rotation: this.animatableProperties.rotation.previous,
                filter: `brightness(${this.animatableProperties.brightness.previous})`
            });

            // loop
            this.firstRAFCycle = false;
            this.loopRender();
        }
    }
})
DURO.FOOTER = (function() {
    var self;

    return {
        init: function(elem) {
            self = this;
            self.el = elem;
            console.log("FOOTER");

            window.addEventListener('resize', this.resizeHandler);
            if($(window).width() < 900) {
                $(elem).appendTo("#main")
            }
        },
        resizeHandler: function(e) {
            let el = $(self.el);

            if($(window).width() < 900) {
                el.appendTo("#main")
            } else {

                el.insertBefore(".scroll-container");
            }
            if(!isTouchScreendevice()) {
                DURO.BUILD.resetScroll();
            }
        }
    }
})

DURO.SEARCH = (function() {
    var self;
    var el;
    var minSearchLenght = 4;
    var template;
    var structure;
    var src;
    return {
        init: function(elem) {
            el = elem
            self = this;
            $(elem).find(".search-btn").on("click", function(){
                $(elem).find(".search-input").focus();
                $(elem).find(".search-input").val("");
                $(elem).find(".search-input-container").toggleClass("expand");
                if(!$(elem).find(".search-input-container").hasClass("expand")) {
                    self.hideResults();
                    $(elem).find(".search-input").blur();
                }
            })
            $(elem).find(".search-input").on("input", this.changeHandler);
            template = $(elem).find('script[type="text/template"]')[0];
            structure = template.cloneNode(true).innerHTML;
            src = $(elem).data("search-json");

            // let pageContainer = $(elem).find(".search-results-container");
            // let scrollInstance = new LocomotiveScroll({
            //     el: pageContainer,
            //     smooth: true,
            //     getDirection: true
            //     // scrollFromAnywhere: true
            //     // ,
            //     // smartphone: {
            //     //     smooth: true
            //     // }
            // });
        },
        closeSearch: function() {
            $(el).find(".search-input-container").removeClass("expand");
            self.hideResults();
        },
        changeHandler: function(e) {
            let inputSearch = $(e.currentTarget).val();
            if(inputSearch.length >= minSearchLenght) {
                self.searchBy(inputSearch);
            } else {
                self.hideResults();
            }
        },
        searchBy: function(search) {
            $.ajax({
                method: "POST",
                url: src,
                data: { search:search },
                dataType: "json"
            }).done(function( response ) {
                self.render(response.results);
            });
        },
        render: function(results) {
            $(el).find(".search-results-container").removeClass("hide");
            $(el).find(".search-results-container").empty();
            for(let i = 0; i < results.length; i++) {
                let title = results[i].title;
                let description = results[i].description;
                let link = results[i].link;

                var templateBuild = DURO.BUILD.templateEngine(structure, {
                    title: title,
                    description: description,
                    link:link
                });

                let item = $(templateBuild);
                $(el).find(".search-results-container").append(item);

                let _opacity = 1 - (i * .2);
                if(_opacity <= 0) {
                    _opacity = .1;
                }
                gsap.fromTo(item, {opacity: 0}, {opacity: _opacity, duration: 0.15, delay: i  * .1});
            }


            $(el).addClass("with-results");
        },
        hideResults: function() {
            $(el).find(".search-results-container").addClass("hide");
        }
    }
})();

DURO.CURSOR = (function() {
    var self;
    var $follow;
    var $circle;
    var $circleMask;
    return {
        init: function(elem) {

            $circle = $(elem);
            $follow = $(".follow");

            // $(window).on('mousemove', this.moveCircle);

            $(document).on({mouseenter: this.hoverFunc, mouseleave: this.unhoverFunc}, "a, button, figure, video, .has-cursor");
            if($(".circle-mask").length) {
                $circleMask = $(".circle-mask");
            }
        },
        moveCircle: function(e) {
            TweenLite.to($circle, 0.3, {
                x: e.clientX,
                y: e.clientY
            });
            TweenLite.to($follow, 0.5, {
                x: e.clientX,
                y: e.clientY
            });
            if($circleMask) {
                DURO.TEASERSCREEN.render(e)

            }
        },
        unhoverFunc:function(e) {
            let $label = $follow.find("p");
            let _followOpacity = 0.3;
            let _circleOpacity = 1;
            
            if(!$(e.currentTarget).data("cursor-label-keep")) {
                $follow.find("p").html("");
                
            } else {
                TweenLite.to([$label, $follow,], 0.3, {opacity: 1, overwrite: true});
                _followOpacity = 1;
                _circleOpacity = 0;
            }
            
            TweenLite.to($circle, 0.3, {
                opacity: _circleOpacity,
                scale: 1
            });
            TweenLite.to($follow, 0.3, {
                width: 20,
                height: 20,
                top: -10,
                left: -10,
                opacity: _followOpacity
            });

        },
        hoverFunc: function(e) {
            let $label = $follow.find("p");
            let $labelColor = "#000000";
            let followOverProperies = {
                width: 100,
                height: 100,
                top: -50,
                left: -50,
                opacity: 1,
                borderColor: "transparent"
            }
            TweenLite.to($follow, 0, {rotation: 0});
            TweenLite.to($label, 0, {fontSize: 15});
            // if($(e.currentTarget).data("cursor-over-bg")) {
            //     followOverProperies.backgroundColor = $(e.currentTarget).data("cursor-over-bg");
            // }
            if($(e.currentTarget).data("cursor-over-fontsize")) {
                TweenLite.to($label, 0.3, {fontSize: $(e.currentTarget).data("cursor-over-fontsize")});
            }
            followOverProperies.rotation = 0;
            if($(e.currentTarget).data("cursor-over-rotation")) {
                followOverProperies.rotation = $(e.currentTarget).data("cursor-over-rotation");
            }
            if($(e.currentTarget).data("cursor-over-border")) {
                followOverProperies.borderColor = $(e.currentTarget).data("cursor-over-border");
            }
            // if($(e.currentTarget).data("cursor-over-color")) {
            //     $labelColor = $(e.currentTarget).data("cursor-over-color");
            // }
            if($(e.currentTarget).data("cursor-over-label")) {
                followOverProperies.opacity = 1;
                TweenLite.to($label, 0.3, {color: $labelColor});

                $label.html($(e.currentTarget).data("cursor-over-label"));
            } else {
                followOverProperies.opacity = .1;

           }

        TweenLite.to($follow, 0.3, followOverProperies);
        TweenLite.to($circle, 0.3, {
        opacity: 1,
        scale: 0
        });

        },
        updateLabel: function(label) {
            let $label = $follow.find("p");
            $label.html(label);
        }
    }
})();


DURO.SOCIAL_MEDIA_WALL__EXTENDED = function () {
    var self;
    return {
        init: function init(elem) {
            self = this;
            console.log("SOCIAL_MEDIA_WALL");
            $.instagramFeed({
                          'username': 'instagram',
                          'container': "#insta_feed",
                          'display_profile': false,
                          'display_biography': false,
                          'display_gallery': true,
                          'display_captions': true,
                          'max_tries': 100,
                          'callback': null,
                          'styling': false,
                'items': 100,
                'items_per_row': 4,
                'margin': 1,
                //'lazy_load': true,
                'on_error': console.error,
                'callback':function(obj){
                  console.log(obj);
                  for (var i = 0; i < 4; i++) {
                    //console.log(obj.edge_owner_to_timeline_media.edges[i].node);
                    var item = obj.edge_owner_to_timeline_media.edges[i].node;
                    $('#insta_feed').append('<div class="col-lg-3 col-md-6 col-xs-1"><a href="https://www.instagram.com/p/'+item.shortcode+'"><div class="box" data-animate-in-out><img style="position:absolute;" src="'+item.thumbnail_src+'" class="img-fluid" /></div></a></div>');
                  }
                  TweenMax.staggerTo(".box", 0, { opacity: 1, y: 20 }, 0.1, self.animInEndHandler);
                  //cntr = cntr+4;

                  $(".load-more-arrows").on("click", function () {
                      self.animOut(self.animIn);
                      $(this).addClass("loaded");
                      //console.log('clck');
                      $('#insta_feed').html('');
                      cntr = cntr+4;
                      for (var i = cntr; i < cntr+4; i++) {
                        //console.log(obj.edge_owner_to_timeline_media.edges[i].node);
                        var item = obj.edge_owner_to_timeline_media.edges[i].node;
                        $('#insta_feed').append('<div class="col-lg-3 col-md-6 col-xs-1" ><a href="https://www.instagram.com/p/'+item.shortcode+'"><div class="box" data-animate-in-out><img style="position:absolute;" src="'+item.thumbnail_src+'" class="img-fluid" /></div></a></div>');
                      }
                  }).on("mouseout", function () {
                      $(this).removeClass("loaded");
                  });

                }
            });
        },
        animIn: function animIn() {
            TweenMax.staggerTo(".box", 0, { opacity: 1, y: 20 }, 0.1, self.animInEndHandler);
        },
        animInEndHandler: function animInEndHandler() {
            console.log("animInEndHandler");
        },
        animOut: function animOut(endHandler) {
            console.log(endHandler);
            if (!endHandler) {
                endHandler = self.animOutEndHandler;
            }
            TweenMax.staggerTo(selectReversed(".box"), 0, { opacity: 0, y: 0 }, 0.2, endHandler);
        },
        animOutEndHandler: function animOutEndHandler() {
            console.log("animOutEndHandler");
        }
    };
}();



DURO.CONTACT_FORM = function () {
    var self;
    return {
        init: function init(elem) {
            self = this;
            $(elem).find('#contact_form').submit(function(e){
                e.preventDefault();
                console.log('::FORM SUBMIT');
                var dataUrl = $(this).data('url');
                var formData = $(this).serializeArray();
                $.ajax({
                  method: "POST",
                  url: "/?eID=contact",
                  dataType: "json",
                  data: formData
                }).done(function(out) {

                    //this.validateResult();
                    if (out.errors == 1)
                    {
                      console.log(out.text);
                      $('#form_out').html(out.text);
                    } else {
                      $('#contact_screen').hide();
                      $('#thanks_screen').removeClass('d-none');
                    }

                });
            });
        }
    };
}();



DURO.JOBS_UPLOAD = function () {
    var self;
    return {
        init: function init(elem) {
            self = this;
            $(elem).find('#contact_form').submit(function(e){
                e.preventDefault();
                console.log('::FORM SUBMIT');
                var dataUrl = $(this).data('url');
                var formData = $(this).serializeArray();
                $.ajax({
                  method: "POST",
                  url: "/?eID=jobs",
                  dataType: "json",
                  data: formData
                }).done(function(out) {

                    //this.validateResult();
                    if (out.errors == 1)
                    {
                      console.log(out.text);
                      $('#form_out').html(out.text);
                    } else {
                      $('#contact_screen').hide();
                      $('#thanks_screen').removeClass('d-none');
                    }

                });
            });
        }
    };
}();


DURO.SLIDER_TEXT = function () {
    var self;
    var countSlides;
    return {
        init: function init(elem) {
            self = this;
            self.el = elem;
            var activeSlide = 1;
            countSlides = $(elem).find('.slider_text_instance li').length;
            var activeProgress = 100/countSlides;
            $(elem).find('.sliderItem-'+activeSlide).addClass('activeSlide');
            self.updateActiveProgressNumber(activeSlide);
            $(elem).find('.progress-bar-current').css({"width":(activeSlide*activeProgress)+"%"});
            $(elem).find('.arrow').click(function(e){
                e.preventDefault();
                var wrapWidth = $(elem).find('.slider-wrap').width();
                var sLeft = $(elem).find('.slider_text_instance').position().left;
                if ($(this).hasClass('prev')) {
                    if (activeSlide > 1)
                    {
                      activeSlide--;
                      self.updateActiveProgressNumber(activeSlide);
                      $(elem).find('.sliderItems').removeClass('activeSlide');
                      TweenMax.staggerTo(".slider_text_instance", 0.5, {x:"+="+wrapWidth}, 0,function(){
                          $(elem).find('.sliderItem-'+activeSlide).addClass('activeSlide');
                      });
                      $(elem).find('.progress-bar-current').css({"width":(activeSlide*activeProgress)+"%"});
                    }
                }
                if ($(this).hasClass('next')) {
                    if (activeSlide < countSlides) {
                      activeSlide++;
                      self.updateActiveProgressNumber(activeSlide);
                      $(elem).find('.sliderItems').removeClass('activeSlide');
                      TweenMax.staggerTo(".slider_text_instance", 0.5, {x:"-="+wrapWidth}, 0,function(){
                           $(elem).find('.sliderItem-'+activeSlide).addClass('activeSlide');
                      });
                      $(elem).find('.progress-bar-current').css({"width":(activeSlide*activeProgress)+"%"});
                    }
                }
            });
        },
        initEvents() {

        },
        updateActiveProgressNumber: function(num) {

            var progress = Math.ceil(num * 100 / countSlides);

            let currentY = (26 * (Math.floor(progress) * countSlides) / 100)- 26;
            if(currentY < 0 ) {
                currentY = 0;
            }

            gsap.to($(self.el).find(".progress-index li"), .5, {y: -currentY});
        }
    };
}();

DURO.JOBS = (function () {
    var self;
    var el;
    var requestAnimationFrameID;
    var glove;
    var bag;
    var sigmoid = function(x) {
        return (x / (1 + Math.abs(x)));
    };

    var glovePosition = {
        x: 0,
        y: 0
    };
    var xVelocity = 0;
    var rotation = 0;
    var audio = new Audio('/frontend/images/punch.mp3');
    var hitVisual;
    var labelEl;
    var labelEncorage;
    var labelSuccess;
    var _hitCount = 0;
    var _hitFail = 0;
    var _hitRandomMessage = getRandomInt(5,15);
    var _hitTotal = 5;
    var offetTopOrigin;

    return {
        init: function init(elem) {

            self = this;
            self.el = elem

            bag = $(elem).find(".boxing-bag");
            labelEl = $(elem).find(".label");
            hitVisual = $(elem).find(".boxing-bag .hit-visual");
            self.prevEvent = {screenX: 0, screenY: 0};
            self.currentEvent = {screenX: 0, screenY: 0};
            labelEncorage = labelEl.data("label-encorage");
            labelSuccess = labelEl.data("label-success");

            this.initEvents(elem);

            if(!isTouchScreendevice()) {
                if($(elem).height() < 900) {
                    $(elem).css("min-height", "900px");
                }
            }
        },
        initEvents: function(elem){
            console.log("initEvents");

            // LOOP bag movement
            // tl = new TimelineMax();
            // tl.fromTo(".boxing-bag", 1, {rotation:5}, {rotation:-5, repeat:-1, yoyo:true, ease:Quad.easeInOut, overwrite: true})

            inView(".boxing-bag")
            .on('enter', function(){
                self.requestAnimationFrameID = requestAnimationFrame(() => self.render());
            })
            .on('exit', function(){
                cancelAnimationFrame(self.requestAnimationFrameID);
                gsap.to($(glove), 1, {x: 0, y:0, xPercent: 50, yPercent: 50, ease: Elastic.easeOut});
            });
            glove = document.querySelector('.boxing-glove');
            offetTopOrigin = $(self.el).offset().top;
            
        },
        render: function(){
            let sensitivityFactor = 20;
            let isAnimating = false;
            var _rotation;

            xVelocity = (mousepos.x - glovePosition.x);

            glovePosition.x = mousepos.x;
            glovePosition.y = mousepos.y;

            rotation = rotation * 0.9 + (sigmoid(xVelocity) * 1.5);

            glove.style.top = (glovePosition.y -  $(glove).parent().offset().top - $(glove).height()/2) + 'px';
            glove.style.left = (glovePosition.x - $(glove).parent().offset().left - $(glove).width()/2) + 'px';

            if (Math.abs(rotation) < 0.01) rotation = 0;

            glove.style.transform = `rotate(${rotation*7}deg)`;

            _rotation = -xVelocity;
            // console.log(xVelocity);
            if(collision(bag,$(glove).find(".hit")) && (xVelocity > sensitivityFactor || xVelocity < -sensitivityFactor) && !isAnimating) {
                isAnimating = true;

                let _hitStrenghtFactor = 4;
                let _xPos = -(getRandomInt(0,100) * sigmoid(xVelocity));
                if((xVelocity > sensitivityFactor * _hitStrenghtFactor || xVelocity < -sensitivityFactor * _hitStrenghtFactor)) {
                    let _scale =  Math.abs(xVelocity/ 100);
                    _hitCount++;

                    if(_scale > 1.3) {
                        _scale = 1;
                    }
                    gsap.to(hitVisual, 0, {opacity:1, scale: _scale, x: _xPos, overwrite: true,  onComplete: function(){
                        gsap.to(hitVisual, 0, {opacity:0, delay: 0.1});

                    }})

                    if(_hitCount == _hitTotal) {
                        // MAKE TRANSITION TO CONTENT
                        console.log("done, make transition");
                        this.updateLabel(labelSuccess);
                        this.showJobs();
                    }
                } else {
                    _hitFail++;
                    if(_hitFail >= _hitRandomMessage && _hitCount < _hitTotal) {
                        this.updateLabel(labelEncorage);
                    }
                }

                var speedAnimation = Math.abs(xVelocity) / 200;
                gsap.to(bag, speedAnimation, {rotation: _rotation, ease: Power4.easeOut, overwrite: true,onComplete: function(){
                    isAnimating = false;
                    // gsap.to(bag, 1, {rotation: 0, ease: Elastic.easeOut})
                }});
            } else {
                if(!isAnimating) {
                    gsap.to(bag, 1, {rotation: 0, ease: Elastic.easeOut, overwrite: true, delay: speedAnimation * 2})
                }
            }
            self.requestAnimationFrameID = requestAnimationFrame(() => self.render());
        },
        updateLabel: function(_label){
            gsap.to(labelEl, .3, {opacity: 0, onComplete: function(){
                labelEl.html(_label);
                gsap.to(labelEl, .3, {opacity: 1});
            }})
        },
        showJobs: function(){
            
            gsap.to($(self.el).find("> .container"), .5, {opacity: 0, delay: 2, ease: Quad.easeOut, onComplete: function(){
                // $(self.el).find("> .container").css({"display": "none"});
                $(self.el).find(".list").css({"visibility": "visible", "opacity": 1});
                gsap.to($(self.el).find(".list"), .6, {opacity: 1, delay: .3, onComplete: function(){
                    // $(self.el).css("min-height", "auto");
                    // $(self.el).find(".container, .row").css("min-height", "auto");
                    gsap.to($("[data-scroll-container]"), {duration: 1, scrollTo: offetTopOrigin});
                }});
            }})

            $(glove).css({"top": 0, "left": 0});
            gsap.to($(glove), 1, {xPercent: 50, yPercent: 50, ease: Elastic.easeOut});
            cancelAnimationFrame(self.requestAnimationFrameID);
        }

    };
});
DURO.CALL_NAV = function () {
    var self;
    var el;
    var stickyCallBottom = 200;
    var oBottom;
    return {
        init: function init(elem) {
            this.initEvents(elem)
            self = this;
            self.el = elem;
            oBottom = $(elem).css("bottom");
        },
        initEvents: function(elem){
            gsap.to($(elem), 0, {xPercent: 0})
            var tween;
            var opened = false;

            if ("ontouchstart" in document.documentElement)
            {
                window.addEventListener('scroll',this.render);
                $(elem).on("touchstart", function(e){
                    console.log(opened);
                    if(!opened) {
                        tween = gsap.to($(elem), .6, {xPercent: -70, ease: Back.easeOut});
                        opened = true;
                    } else {
                        tween = gsap.to($(elem), .5, {xPercent:0, overwrite: true, ease: Back.easeIn})
                        opened = false;
                    }
                });
            }
            else {
                $(elem).on("mouseenter", function(e){
                    tween = gsap.to($(elem), .6, {xPercent: -70, ease: Back.easeOut});

                });
                $(elem).on("mouseleave", function(e){
                    tween = gsap.to($(elem), .5, {xPercent:0, overwrite: true, ease: Back.easeIn})
                });
            }
        },
        render: function(e) {
            let documentHeight = $(document).height();
            
            if (typeof window.visualViewport !== 'undefined') {
                documentHeight = $(document).height() - window.visualViewport.height;
            }
            // console.log($(window).scrollTop(), documentHeight, (documentHeight - stickyCallBottom))

            if($(window).scrollTop() >= (documentHeight - stickyCallBottom)) {
                $(self.el).css("bottom", stickyCallBottom);
            } else {
                $(self.el).css("bottom", oBottom);
            }
        }

    };
}();
function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
DURO.SLIDER = function () {
    var self;
    var el;
    var _max;
    var progressEl;
    var total;
    return {
        init: function init(elem) {

            self = this;
            self.el = elem;
            _max = $(elem).find(".pin-wrap").width() - $(window).width() / 2;
            $(elem).find(".progress-index").html("");
            self.progressEl = $(elem).parent().find(".progress-bar-container");
            $(elem).find(".progress-bar-total").html(pad(($(elem).find(".pin-wrap").children().length), 2));
            
            var count = "";
            total = $(elem).find(".pin-wrap").children().length;
            for(let i = 0; i < $(elem).find(".pin-wrap").children().length; i++) {
                count += "<li>"+pad((i+1), 2)+"</li>";
            } 
            
            $(elem).find(".progress-index").html(count);
            Draggable.create($(elem).find(".pin-wrap:not(.is-slick)"),
                {
                    type:"x",
                    edgeResistance:0.65,
                    inertia:true,
                    lockAxis:true,
                    bounds:{minX:0, maxX:-_max, minY:0, maxY:0},
                    onDrag: self.updateProgress,
                    onThrowUpdate: self.updateProgress
            });
            $(elem).find(".pin-wrap.is-slick").on('afterChange', this.updateProgressSlick);
            
            $(elem).find(".pin-wrap.is-slick").slick({
                infinite: false,
                slidesToScroll: 1,
                variableWidth: true,
                touchThreshold:100,
                dots: false,
                arrows: true,
                prevArrow: $('.arrow.prev'),
                nextArrow: $('.arrow.next')
            });
        },
        updateProgress: function(event){
            let _current = $(self.el).find(".pin-wrap").offset().left;
            // currentSlide = currentSlide + 1;
            var progress = Math.abs(Math.ceil(_current * 100 / _max));

            console.log(progress);
            gsap.to(self.progressEl.find(".progress-bar-current"), .3, {width: percentToPixel(self.progressEl.find(".progress-bar-current"), progress)})
            let currentY = Math.floor(((progress) * (($(self.el).find(".pin-wrap").children().length) * 26) / 100)-25);
            console.log(currentY)
            if(currentY < 0 ) {
                currentY = 0;
            }
            // console.log(total, progress, (progress * total) / 100, currentY)

            gsap.to(self.progressEl.find(".progress-index li"), .5, {y: -currentY});
        }
        ,
        updateProgressSlick: function(event, slick, currentSlide){
            currentSlide = currentSlide + 1;
            var progress = Math.ceil(currentSlide * 100 / total);

            console.log(currentSlide, total,  progress);
            gsap.to(self.progressEl.find(".progress-bar-current"), .3, {width: percentToPixel(self.progressEl.find(".progress-bar-current"), progress)})
            let currentY = (26 * (progress * total) / 100)- 26;
            if(currentY < 0 ) {
                currentY = 0;
            }
            // console.log(total, progress, (progress * total) / 100, currentY)

            gsap.to(self.progressEl.find(".progress-index li"), .5, {y: -currentY});
        }
    };
}
DURO.TEASERSCREEN = function () {
    var self;
    var el;
    var $circle;
    var $svg;
    var $header;
    var $button;
    var videoStatus = false;
    var initialScale = 5;
    var _video;
    return {
        init: function init(elem) {
            self = this;
            el = elem
            $circle = $(".circle-mask");
            $svg = $("svg");
            $header = $("header h1");
            $button = $("header a.btn");
            gsap.to($circle, 0, {scale: 0});
            
            let id = $(elem).find("video").attr("id");
            _video = document.getElementById(id);
            
            console.log("_video", _video);
            _video.onended = this.videoEnded;
            _video.onpause = this.pauseVideo;
            this.initEvents(elem);
           
        },
        initEvents: function(elem){
            
            console.log("autoplay", $(elem));
            // if($(elem).attr("data-autoplay")) {
            //     gsap.to($circle, 2, {scale: 30, transformOrigin: 'center', overwrite: true, onComplete: function(){
            //         $header.removeClass("fade-in");
            //         $button.removeClass("fade-in");
            //         gsap.to(this, 2, {onComplete: self.pauseVideo});
            //     }});
            // } else {
                gsap.to($circle, 1, {transformOrigin: 'center', scale: initialScale, overwrite: true, onComplete: function(){
                    DURO.CURSOR.updateLabel("Play");
                }});
                // self.pauseVideo();
                $header.addClass("fade-in");
                $button.addClass("fade-in");
            // }
            $(elem).on("click", function(e){
               
                if(!$(e.target).hasClass("btn")) {
                    e.preventDefault();
                    if(!self.videoStatus) {
                        self.playVideo();
                    } else {

                        self.pauseVideo();
                    }
                }
                
            });
            console.log("video",)
            
        },
        videoEnded: function(){
            console.log("videoEnded");
            gsap.to($circle, 1, {transformOrigin: 'center', scale: initialScale, overwrite: true});
            var vid = this;
            gsap.to($svg, .2, {opacity: 1, onComplete: function(){
                DURO.CURSOR.updateLabel("Play");
                $header.addClass("fade-in");
                $button.addClass("fade-in");
                vid.play();
                var volume = {value:vid.volume}
                
                TweenMax.to(volume, 1, {
                value:0,
                ease:Quad.easeOut,
                    onUpdate:function() {
                        vid.volume = volume.value;
                    }
                });
            }})
        },
        playVideo: function(){
            gsap.to($circle, 1, {scale: 20, transformOrigin: 'center', xPercentage: -50, yPercentage: -50, ease: Quad.easeInOut, onComplete: function(){
               
            }});
            gsap.to($svg, .2, {opacity: 0, delay: 1, onComplete: function(){
                
                $header.removeClass("fade-in");
                $button.removeClass("fade-in");
                
            }})
            gsap.to(this, 2, {
                onComplete: function(){
                    $button.css("pointer-events", "none");
                }
            })
            DURO.CURSOR.updateLabel("Pause");
            DURO.VIDEO.forcePlay(1);
            self.videoStatus = true;
        },
        pauseVideo: function(){
            gsap.to($svg, .2, {opacity: 1, onComplete: function(){
                $header.addClass("fade-in");
                $button.addClass("fade-in");
            }})
            gsap.to($circle, 1, {scale: initialScale, transformOrigin: 'center', xPercentage: 0, yPercentage: 0, ease: Quad.easeInOut, onComplete: function(){
                
                $button.css("pointer-events", "auto");
                DURO.CURSOR.updateLabel("Play");
                DURO.VIDEO.stop();

            }});
            self.videoStatus = false;

        },
        render: function(e){
            TweenLite.to($circle, 0.7, {
 
                x: (e.clientX - $circle.parent().parent().parent().parent().offset().left),
                y: (e.clientY - $circle.parent().parent().parent().parent().offset().top)
            });

        }
    };
}();
$(function() {
  // INIT
  DURO.BUILD.init();
});

const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;
const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;

function calcWinsize() {
    return {width: window.innerWidth, height: window.innerHeight};
};
function selectReversed(query) {
    var nodes = document.querySelectorAll(query);
    nodes = Array.prototype.slice.call(nodes, 0);
    return nodes.reverse();
}
// Gets the mouse position
function getMousePos(e){

    DURO.CURSOR.moveCircle(e);
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    }
    else if (e.clientX || e.clientY)    {
        posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
    }
    mousepos = { x : posx, y : posy, clientX: e.clientX, clientY: e.clientY };

    return { x : posx, y : posy }
};

function distance(x1,y1,x2,y2) {
    var a = x1 - x2;
    var b = y1 - y2;

    return Math.hypot(a,b);
}
function elementDistanceToCursor(b) { // get distance to element
    // var b = elem.getBoundingClientRect();
    var dx = 0;
    var dy = 0;

    //Compute distance to elem in X
    if (mousepos.x < b.left)
       dx = b.left - mousepos.x;
    else if (mousepos.x > b.right)
        dx = b.right - mousepos.x;

    //Compute distance to elem in Y
    if (mousepos.y < b.top)
       dy = b.top - mousepos.y;
    else if (mousepos.y > b.bottom)
       dy = b.bottom - mousepos.y;

    return Math.floor(Math.sqrt(dx * dx + dy * dy));
}
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getAbsoluteBoundingRect (el) {
    var doc  = document,
    win  = window,
    body = doc.body,
    // pageXOffset and pageYOffset work everywhere except IE <9.
    offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
    (doc.documentElement || body.parentNode || body).scrollLeft,
    offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
    (doc.documentElement || body.parentNode || body).scrollTop,
    rect = el.getBoundingClientRect();
    if (el !== body) {
    var parent = el.parentNode;
    // The element's rect will be affected by the scroll positions of
    // *all* of its scrollable parents, not just the window, so we have
    // to walk up the tree and collect every scroll offset. Good times.
    while(parent !== body && parent !== null) {
    offsetX += parent.scrollLeft;
    offsetY += parent.scrollTop;
    parent   = parent.parentNode;
    }
    }
    return {
    bottom: rect.bottom + offsetY,
    height: rect.height,
    left  : rect.left + offsetX,
    right : rect.right + offsetX,
    top   : rect.top + offsetY,
    width : rect.width
    };
}
function draw(rect) {
    const div = document.createElement("div");
    // Might be needed on crazy pages, but makes the console output for the div crazy large.
    // div.style.all = "unset";
    div.style.position = "absolute";
    div.style.zIndex = "2147483647";
    // At least _try_ to scroll along. Won’t work for inner scroll.
    div.style.left = `${window.scrollX + rect.left}px`;
    div.style.top = `${window.scrollY + rect.top}px`;
    div.style.width = `${rect.width}px`;
    div.style.height = `${rect.height}px`;
    div.style.boxSizing = "border-box";
    div.style.border = "1px solid magenta";
    div.style.pointerEvents = "none";
    document.documentElement.append(div);
    return div;
}
function collision($div1, $div2) {
    var x1 = $div1.offset().left;
    var y1 = $div1.offset().top;
    var h1 = $div1.outerHeight(true);
    var w1 = $div1.outerWidth(true);
    var b1 = y1 + h1;
    var r1 = x1 + w1;
    var x2 = $div2.offset().left;
    var y2 = $div2.offset().top;
    var h2 = $div2.outerHeight(true);
    var w2 = $div2.outerWidth(true);
    var b2 = y2 + h2;
    var r2 = x2 + w2;
    if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;

    return true;
}
function removeFromString(arr,str){
    let regex = new RegExp("\\b"+arr.join('|')+"\\b","gi")
    return str.replace(regex, '')
}
// DURO.LABELCOMPONENT = (function() {
//     var requestAnimationFrameID;
//     var self;
//     var classes = ["component", "animate-in", " in", "container ", "container-fluid", "pr-lg-0", "px-0", "pt-100", "pb-50", "has-", "dark-grey-color-bg", " ", ];
//     return {
//         init: function(elem) {
//             self = this;
//             // self.requestAnimationFrameID = requestAnimationFrame(() => self.render(elem));
//             var component = $('.component');
//             component.each(function(){
//                 let item = $(this);

//                 let template = item.attr("class");
//                 template = removeFromString(classes, template);
//                 let offset = item.offset().top;
//                 $(this).attr("id", template);
//                 let button = '<a data-scroll-to href="#'+template+'" class="btn small" data-scrollto="'+offset+'">'+template+'</a>&nbsp;&nbsp;&nbsp;';
//                 $(".index").append(button);
//             });
//             $("[data-scrollto]").on("click", function(){
//                 gsap.to($("[data-scroll-container]"), {duration: 2, scrollTo: $(this).data("scrollto")});
//             })

//         },
//         render: function(elem){
//             // // DELETE AFTER
//             // document.addEventListener('scroll',function(){

//                 var label = $('.label-component');
//                 var component = $('.component');

//                 if(label.length) {
//                     component.each(function(){
//                         let item = $(this);

//                         if((collision($(this), $(label)))) {
//                             let template = item.attr("class");
//                             template = removeFromString(classes, template);
//                             label.html(template);
//                         }
//                     });
//                 }


//             // self.requestAnimationFrameID = requestAnimationFrame(() => self.render(elem));
//         }
//     }
// })();
